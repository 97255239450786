import { navigate } from "gatsby"
import { useEffect, useState } from "react"

const SyncSessionPage = () => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (process.env.GATSBY_RT_26_07_2022_SYNC_SESSION !== "true") {
      navigate("/")
      return
    }

    // Logic to insure if user lands on this page in browser, to redirect to home page
    const isInIframe = window.self !== window.top
    setIsReady(isInIframe)

    if (!isInIframe) {
      navigate("/")
      return
    }
  }, [])

  useEffect(() => {
    if (!isReady) {
      return
    }

    const listener = event => {
      // The replace will remove the trailing slash in string
      if (
        !event.origin.startsWith(
          `${process.env.GATSBY_GETTING_STARTED_URL}`.replace(/\/$/, "")
        )
      ) {
        return
      }

      const { data } = event
      if (typeof data !== "object" || !data.action) {
        return
      }

      const { action, key, value } = data

      switch (action) {
        case "removeItem":
          window.localStorage.removeItem(key)
          break
        case "setItem":
          window.localStorage.setItem(key, value)
          break
        case "clear":
          window.localStorage.clear()
          break
        default:
          console.warn("action not supported: ", action)
          break
      }
    }

    window.addEventListener("message", listener)
    return () => {
      window.removeEventListener("message", listener)
    }
  }, [isReady])

  return null
}

export default SyncSessionPage
